import { SvgIcon } from '@mui/material'

const YelpIcon = (props) => (
  <SvgIcon width="110" height="54" viewBox="0 0 110 54" fill="none" {...props}>
    <path
      d="M66.3869 20.4087C66.3942 20.4124 66.396 20.4124 66.4015 20.4124C66.407 20.416 66.4088 20.416 66.4161 20.416L66.3869 20.4087Z"
      stroke="#ABA69C"
      strokeWidth="0.71428"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.62 43.9937H102.614C102.611 43.9937 102.609 43.9992 102.604 44.001L102.62 43.9937Z"
      stroke="#ABA69C"
      strokeWidth="0.71428"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.6625 22.9961C76.0123 24.0447 75.7377 27.3468 75.9635 29.5384C76.043 30.2617 76.173 30.8651 76.3627 31.2249C76.6228 31.7229 77.0599 32.0191 77.5584 32.0373C77.8781 32.0536 78.0768 31.9991 84.0879 30.0546C84.0879 30.0546 86.7593 29.1968 86.7701 29.1913C87.4366 29.0205 87.8846 28.4044 87.9279 27.6175C87.9713 26.8106 87.5576 26.0982 86.8749 25.8402C86.8749 25.8402 84.991 25.0678 84.9874 25.0678C78.5283 22.3873 78.2375 22.2819 77.9124 22.2782C77.4139 22.2582 76.9714 22.5108 76.6625 22.9961Z"
      fill="#ABA69C"
    />
    <path
      d="M91.1123 42.9687C91.2171 42.6634 91.2297 42.4544 91.2478 36.101C91.2478 36.101 91.2622 33.2932 91.264 33.266C91.3074 32.5772 90.8649 31.9502 90.1406 31.6704C89.3946 31.3814 88.5908 31.5595 88.1393 32.1247C88.1393 32.1247 86.8207 33.6985 86.8171 33.6985C82.2925 39.0469 82.1029 39.2941 81.9927 39.6103C81.9258 39.7956 81.8987 39.9955 81.9204 40.1936C81.9475 40.4771 82.0758 40.757 82.2871 41.0187C83.3365 42.2726 88.3686 44.1354 89.9762 43.8592C90.5343 43.7574 90.9407 43.4412 91.1123 42.9687Z"
      fill="#ABA69C"
    />
    <path
      d="M101.321 40.7571C102.838 40.1483 106.147 35.9121 106.38 34.2819C106.462 33.7149 106.285 33.2261 105.896 32.9135C105.642 32.7209 105.448 32.6464 99.4408 30.6618C99.4408 30.6618 96.8055 29.7859 96.7712 29.7695C96.1336 29.5206 95.4057 29.7514 94.9162 30.3583C94.4068 30.9817 94.331 31.8049 94.7374 32.4265L95.7976 34.1638C99.3631 39.9902 99.6359 40.4027 99.8923 40.6044C100.29 40.9188 100.795 40.9697 101.321 40.7571Z"
      fill="#ABA69C"
    />
    <path
      d="M98.2162 25.757C105.044 24.0923 105.307 24.0051 105.577 23.827C105.99 23.5471 106.198 23.0783 106.162 22.5076C106.162 22.4894 106.165 22.4695 106.162 22.4495C105.987 20.763 103.05 16.376 101.603 15.6636C101.09 15.4164 100.577 15.4328 100.151 15.7199C99.8869 15.8926 99.6937 16.1543 96.0379 21.1846C96.0379 21.1846 94.387 23.4472 94.3671 23.469C93.9318 24.0015 93.9246 24.7647 94.3509 25.419C94.7916 26.0968 95.5357 26.4276 96.2185 26.2368C96.2185 26.2368 96.1914 26.2858 96.1842 26.2949C96.5201 26.1677 97.1216 26.0187 98.2162 25.757Z"
      fill="#ABA69C"
    />
    <path
      d="M91.3634 20.0848C91.2459 17.3625 90.4313 5.24091 90.3356 4.67936C90.1983 4.17051 89.8028 3.80704 89.2356 3.66348C87.4926 3.22914 80.8313 5.10643 79.5976 6.38765C79.2002 6.80381 79.0539 7.3163 79.1731 7.77063C79.3682 8.17226 87.6209 21.237 87.6209 21.237C88.8401 23.227 89.8353 22.918 90.1622 22.8144C90.4855 22.7145 91.4753 22.4055 91.3634 20.0848Z"
      fill="#ABA69C"
    />
    <path
      d="M6.82845 48.8166C7.68821 49.6181 9.31743 49.8453 11.2971 49.44C13.152 49.0711 14.3026 48.2369 15.4098 44.695L20.9242 27.2632C21.2096 26.3382 21.1879 25.5349 20.861 24.9388C20.5955 24.459 20.153 24.1337 19.528 23.9665C18.5454 23.6958 17.319 23.723 16.5459 26.38L13.6524 36.268C13.6524 36.268 7.75866 25.066 7.75504 25.0642C7.38296 24.3155 6.292 23.7794 5.18117 24.2228C3.73258 24.7771 3.25032 26.0874 3.82831 27.7757C3.82831 27.7757 10.9972 42.4542 11.0135 42.4942C11.0135 42.4942 11.2085 43.0176 11.2085 43.103C11.2085 43.103 11.2736 43.501 11.2736 43.5155C11.2591 44.0807 11.0713 44.5568 10.7353 44.8749C10.4048 45.1875 9.946 45.351 9.40955 45.3292C9.40955 45.3292 8.29149 45.3038 8.2066 45.3038C7.05784 45.4455 6.33535 46.1088 6.21975 47.1338C6.13847 47.7935 6.3498 48.3732 6.82845 48.8166Z"
      fill="#ABA69C"
    />
    <path
      d="M35.076 32.2882H27.3996C27.1918 32.1355 27.0907 31.8793 27.246 31.1087C27.3436 30.7035 27.4483 30.34 27.7554 29.8312C27.8547 29.6785 27.9577 29.5222 28.057 29.3659C28.6188 28.6517 29.3792 28.1937 30.2444 27.9902C32.9429 27.3269 34.8249 28.7062 35.1302 31.0597C35.2765 32.0865 35.1808 32.2882 35.076 32.2882ZM39.7939 30.9434C39.5464 28.4245 38.5801 26.4582 36.996 25.2533C35.309 23.9648 32.9212 23.5541 30.0944 24.063C26.5506 24.6809 24.4229 27.2287 23.9352 31.4413C23.3355 36.4771 24.1592 40.2717 26.3248 42.418C27.9577 44.0408 30.3726 44.686 33.3095 44.2862C37.4982 43.6937 39.382 41.3803 39.382 39.9173C39.382 38.8415 38.7354 37.9946 37.7853 37.8656C37.2417 37.7565 36.7829 37.8656 35.7678 38.7797C34.3047 40.0627 32.6087 40.5788 31.1059 40.1954C29.9608 39.9028 29.0667 39.1086 28.6621 38.02C28.6621 38.02 28.4815 37.3004 28.4923 37.3803C28.3695 36.7315 28.3135 36.0882 28.7109 35.8265L28.8174 35.7538H38.0183C38.8673 35.7538 39.2972 35.643 39.5446 35.374C40.0504 34.8088 40.0106 33.195 39.7939 30.9434Z"
      fill="#ABA69C"
    />
    <path
      d="M48.135 17.6227C47.4071 17.3247 46.5853 17.4046 45.8646 17.8371C45.171 18.2497 44.882 18.8785 44.882 20.0143V42.0875C44.882 42.0875 45.003 42.8762 45.0157 42.9108C45.171 43.4614 45.5756 43.9303 46.1373 44.1865C46.7605 44.4773 47.4794 44.4719 48.1224 44.1684C48.9225 43.8194 49.394 43.0144 49.394 42.0167V19.9016C49.394 19.9016 49.2549 18.833 49.2549 18.813C49.1266 18.3006 48.7058 17.8571 48.135 17.6227Z"
      fill="#ABA69C"
    />
    <path
      d="M65.4964 40.043C62.4584 40.043 61.9743 37.3297 61.8786 35.2816C61.8732 35.2762 61.8388 34.0822 61.8388 34.0822C61.8388 34.0822 61.8732 33.1953 61.8732 33.1608C62.07 29.617 62.7979 28.2195 64.6565 27.8306C65.9046 27.5398 66.8583 27.6816 67.5736 28.2613C68.6483 29.1318 69.1215 30.9746 69.0782 34.0495C69.0475 37.6496 68.4243 40.043 65.4964 40.043ZM65.7222 23.8361C63.6866 23.4181 61.9454 23.7689 60.271 24.9101C60.271 24.9101 60.0832 25.0628 60.0109 25.1064L59.861 25.1827L59.4022 25.3899L59.2722 24.872L59.162 24.6848L59.1241 24.5685L59.1114 24.534C59.1114 24.534 58.9995 24.3831 58.9832 24.3595C58.5027 23.6071 57.1968 23.2618 56.0192 23.9379C55.443 24.2668 55.1125 24.7302 55.1125 26.1132V47.7775C55.1125 48.9643 55.4827 49.7021 56.2919 50.091C56.9747 50.4363 57.7423 50.4636 58.4016 50.1782C58.9615 49.9311 59.3733 49.4949 59.5269 48.9643L59.6623 48.0356V44.2865L59.6226 43.6831L59.6389 43.505L59.6623 43.4196L59.8845 42.7054L60.5582 43.1561C60.5582 43.1561 60.728 43.2688 60.8003 43.3451L61.0026 43.4796C61.0044 43.4814 61.2374 43.6177 61.2374 43.6177C61.241 43.6177 61.4541 43.7231 61.4541 43.7231C63.2603 44.5809 65.6771 44.4809 67.6224 43.4723C70.0716 42.1856 71.2818 39.9031 71.5617 36.0703C72.147 28.4103 70.3949 24.7502 65.7222 23.8361Z"
      fill="#ABA69C"
    />
    <path
      d="M75.1615 41.7383H75.4126C75.6167 41.7383 75.8082 41.7274 75.8082 41.4966C75.8082 41.2894 75.633 41.2676 75.4758 41.2676H75.1615V41.7383ZM74.9556 41.0823H75.4939C75.8461 41.0823 76.0105 41.2113 76.0105 41.5057C76.0105 41.771 75.8425 41.8819 75.6221 41.9055L76.0466 42.5579H75.8244L75.4234 41.9237H75.1615V42.5579H74.9556V41.0823ZM75.4307 42.8996C76.0249 42.8996 76.5036 42.4289 76.5036 41.8056C76.5036 41.1931 76.0249 40.7206 75.4307 40.7206C74.8346 40.7206 74.356 41.1931 74.356 41.8183C74.356 42.4289 74.8346 42.8996 75.4307 42.8996ZM75.4307 40.5371C76.1243 40.5371 76.7077 41.0859 76.7077 41.8056C76.7077 42.5379 76.1243 43.085 75.4307 43.085C74.7353 43.085 74.1537 42.5379 74.1537 41.8183C74.1537 41.0859 74.7353 40.5371 75.4307 40.5371Z"
      fill="#ABA69C"
    />
  </SvgIcon>
)

export default YelpIcon
