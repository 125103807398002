import {
  Box,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from '@mui/material'
import { Entry } from 'contentful'
import { FunctionComponent, useState } from 'react'
import { colors } from 'lib/root'
import { TypeReviewFields } from 'lib/types/ctf-marketing'
import { kebabCase } from 'lib/utils'
import ReviewsCarousel from 'components/ReviewsCarousel'
import LocalBusiness from './LocalBusiness'
import DownArrow from './icons/DownArrow'

export const reviewCardWidth = 378

interface ReviewCategoriesProps {
  categories: string[]
  activeCategory: string
  onSelectChange: SelectProps['onChange']
  onTabChange: TabsProps['onChange']
}

const ReviewCategories: FunctionComponent<ReviewCategoriesProps> = ({
  categories,
  activeCategory,
  onSelectChange,
  onTabChange,
}) => (
  <>
    <FormControl variant="standard" fullWidth sx={{ display: { md: 'none' } }}>
      <InputLabel
        htmlFor="review-categories-select"
        id="reviews-categories-select-label"
      >
        Review category
      </InputLabel>
      <Select
        labelId="review-categories-select-label"
        id="review-categories-select"
        value={activeCategory}
        onChange={onSelectChange}
        IconComponent={DownArrow}
        label="Review category"
      >
        {categories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    {categories.length > 0 && (
      <Box
        sx={{
          position: 'relative',
          borderBottom: `1px solid ${colors.core.grey[400]}`,
        }}
      >
        <Tabs
          value={activeCategory}
          onChange={onTabChange}
          sx={{
            display: { xs: 'none', md: 'block' },
            overflowX: 'hidden',
            lineHeight: 0,
          }}
        >
          {categories.map((category) => {
            const categorySlug = kebabCase(category)

            return (
              <Tab
                label={category}
                key={categorySlug}
                id={categorySlug}
                value={category}
                sx={{
                  fontSize: '1.25rem !important',
                  lineHeight: 1.5,
                  fontWeight: 400,
                  textTransform: 'none',
                  opacity: 1,
                  '&.Mui-selected': {
                    fontWeight: 500,
                  },
                  '&.MuiTab-root': {
                    px: 0,
                    pb: 2,
                    mr: 3,
                    minWidth: '0 !important',
                  },
                }}
              />
            )
          })}
        </Tabs>
      </Box>
    )}
  </>
)

interface Props {
  reviews?: Entry<TypeReviewFields>[]
  reviewHeading?: string
}

const ReviewsModule: FunctionComponent<Props> = ({
  reviews,
  reviewHeading,
}) => {
  const carouselReviews = reviews.map((review) => review.fields)
  const allCategories = carouselReviews.map((review) => review.category)
  const categories = [...new Set(allCategories)]
  const [activeCategory, setActiveCategory] = useState(categories[0])

  const handleSelectChange = (event: SelectChangeEvent) =>
    setActiveCategory(event.target.value)
  const handleTabChange = (_, value: string) => setActiveCategory(value)

  return (
    <>
      <Container maxWidth="xl" sx={{ pb: 5 }}>
        <Divider
          sx={{
            borderBottomWidth: 3,
            borderColor: colors.core.grey[700],
          }}
        />
        <Typography
          component="h2"
          variant="h3"
          sx={{
            mt: { xs: 3, md: 5 },
            mb: 5,
            color: colors.core.copper[600],
            fontSize: { xs: '2rem', md: '2.5rem' },
          }}
        >
          {reviewHeading}
        </Typography>
        {categories.length > 0 && activeCategory && (
          <ReviewCategories
            categories={categories}
            activeCategory={activeCategory}
            onSelectChange={handleSelectChange}
            onTabChange={handleTabChange}
          />
        )}
      </Container>
      <ReviewsCarousel
        activeReviews={carouselReviews.filter(
          (review) => review.category === activeCategory,
        )}
        activeCategory={activeCategory}
      />
      <LocalBusiness review={reviews?.map((review) => review.fields)} />
    </>
  )
}

export default ReviewsModule
