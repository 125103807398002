import { Box, Rating, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { nantes } from 'lib/fonts'
import { colors } from 'lib/root'
import { TypeReviewFields } from 'lib/types/ctf-marketing'
import GoogleIcon from 'components/icons/GoogleIcon'
import YelpIcon from 'components/icons/YelpIcon'

interface Props {
  review: TypeReviewFields
}

const ReviewCard: FunctionComponent<Props> = ({ review }) => {
  const { author, location, body, site } = review

  return (
    <Box
      sx={{
        cursor: 'grab',
        userSelect: 'none',
        px: { xs: 3, md: 2.5 },
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: 'auto' },
          pt: 3,
          px: 5,
          pb: 5,
          bgcolor: colors.core.grey[200],
          color: colors.core.grey[800],
          '& p': {
            m: 0,
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: nantes.style.fontFamily,
            fontSize: '1.5rem !important',
          }}
        >
          {author}
        </Typography>
        <Typography
          sx={{
            fontSize: '1.125rem !important',
          }}
        >
          {location}
        </Typography>
        <Rating
          value={5}
          readOnly
          role="img"
          aria-label="5 star review"
          sx={{
            color: colors.core.grey[800],
            mt: 1,
            mb: { xs: 5, md: 6 },
          }}
        />
        <Typography
          sx={{
            fontSize: '1.125rem !important',
            lineClamp: 10,
            overflow: 'hidden',
          }}
        >
          {body}
        </Typography>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            mt: 3,
            '& > .MuiSvgIcon-root': {
              width: 'auto',
              height: 'auto',
            },
            '& path': {
              fill: colors.core.grey[700],
            },
          }}
        >
          {site === 'Google' && <GoogleIcon />}
          {site === 'Yelp' && <YelpIcon />}
        </Box>
      </Box>
    </Box>
  )
}

export default ReviewCard
