import { SvgIcon } from '@mui/material'

const GoogleIcon = (props) => (
  <SvgIcon
    width="125"
    height="42"
    viewBox="0 0 125 42"
    fill="none"
    alt="Google Reviews"
    {...props}
  >
    <path
      d="M52.8424 21.5387C52.8424 27.3685 48.2818 31.6644 42.6848 31.6644C37.0878 31.6644 32.5272 27.3685 32.5272 21.5387C32.5272 15.6679 37.0878 11.4131 42.6848 11.4131C48.2818 11.4131 52.8424 15.6679 52.8424 21.5387ZM48.3959 21.5387C48.3959 17.8957 45.7526 15.4031 42.6848 15.4031C39.617 15.4031 36.9737 17.8957 36.9737 21.5387C36.9737 25.1453 39.617 27.6744 42.6848 27.6744C45.7526 27.6744 48.3959 25.1407 48.3959 21.5387Z"
      fill="#ABA69C"
    />
    <path
      d="M74.7554 21.5387C74.7554 27.3685 70.1948 31.6644 64.5978 31.6644C59.0009 31.6644 54.4402 27.3685 54.4402 21.5387C54.4402 15.6724 59.0009 11.4131 64.5978 11.4131C70.1948 11.4131 74.7554 15.6679 74.7554 21.5387ZM70.3089 21.5387C70.3089 17.8957 67.6657 15.4031 64.5978 15.4031C61.53 15.4031 58.8867 17.8957 58.8867 21.5387C58.8867 25.1453 61.53 27.6744 64.5978 27.6744C67.6657 27.6744 70.3089 25.1407 70.3089 21.5387Z"
      fill="#ABA69C"
    />
    <path
      d="M95.7554 12.0248V30.2035C95.7554 37.6813 91.3454 40.7355 86.132 40.7355C81.2244 40.7355 78.2707 37.4531 77.1568 34.7687L81.0281 33.1572C81.7174 34.8053 83.4065 36.75 86.1274 36.75C89.4646 36.75 91.5326 34.6911 91.5326 30.8153V29.359H91.3774C90.3822 30.587 88.4648 31.6598 86.0452 31.6598C80.9824 31.6598 76.3441 27.2498 76.3441 21.5753C76.3441 15.8596 80.9824 11.4131 86.0452 11.4131C88.4602 11.4131 90.3776 12.4859 91.3774 13.6774H91.5326V12.0294H95.7554V12.0248ZM91.8476 21.5753C91.8476 18.0098 89.4691 15.4031 86.4424 15.4031C83.3746 15.4031 80.8044 18.0098 80.8044 21.5753C80.8044 25.1042 83.3746 27.6744 86.4424 27.6744C89.4691 27.6744 91.8476 25.1042 91.8476 21.5753Z"
      fill="#ABA69C"
    />
    <path
      d="M102.717 1.36914V31.0431H98.3804V1.36914H102.717Z"
      fill="#ABA69C"
    />
    <path
      d="M119.618 24.871L123.069 27.1719C121.955 28.8199 119.271 31.6595 114.633 31.6595C108.88 31.6595 104.585 27.213 104.585 21.5339C104.585 15.5123 108.917 11.4082 114.135 11.4082C119.39 11.4082 121.96 15.5899 122.8 17.8497L123.261 19.0002L109.725 24.6062C110.761 26.6378 112.373 27.6741 114.633 27.6741C116.897 27.6741 118.467 26.5602 119.618 24.871ZM108.995 21.228L118.043 17.4708C117.545 16.2062 116.048 15.3252 114.286 15.3252C112.026 15.3252 108.88 17.3202 108.995 21.228Z"
      fill="#ABA69C"
    />
    <path
      d="M16.1106 18.9048V14.609H30.5869C30.7285 15.3577 30.8015 16.2433 30.8015 17.202C30.8015 20.4251 29.9204 24.4105 27.0809 27.2501C24.3189 30.1261 20.79 31.6601 16.1152 31.6601C7.45042 31.6601 0.164337 24.6022 0.164337 15.9375C0.164337 7.27267 7.45042 0.214844 16.1152 0.214844C20.9087 0.214844 24.3235 2.09571 26.8891 4.54723L23.8578 7.57854C22.018 5.85289 19.5254 4.51071 16.1106 4.51071C9.78325 4.51071 4.83455 9.61006 4.83455 15.9375C4.83455 22.2648 9.78325 27.3642 16.1106 27.3642C20.2148 27.3642 22.5522 25.7161 24.0496 24.2188C25.2639 23.0044 26.0628 21.2696 26.3778 18.9003L16.1106 18.9048Z"
      fill="#ABA69C"
    />
    <path
      d="M37.8621 34.6632V37.4242H39.4837C39.8927 37.4242 40.2397 37.2927 40.5245 37.0298C40.8094 36.7595 40.9518 36.4272 40.9518 36.0327C40.9518 35.6675 40.8167 35.3498 40.5464 35.0795C40.2835 34.8019 39.9511 34.6632 39.5494 34.6632H37.8621ZM37.8621 41.5438H36.8541V33.699H39.5275C40.2068 33.699 40.7838 33.9254 41.2586 34.3783C41.7407 34.8238 41.9817 35.3753 41.9817 36.0327C41.9817 36.5732 41.8028 37.0553 41.4449 37.479C41.0943 37.8953 40.6487 38.1619 40.1082 38.2788L40.0863 38.3117L42.2885 41.5V41.5438H41.0943L38.9797 38.3665H37.8621V41.5438Z"
      fill="#ABA69C"
    />
    <path
      d="M45.3165 41.7192C44.5276 41.7192 43.8775 41.4489 43.3662 40.9084C42.8549 40.3678 42.5993 39.6849 42.5993 38.8595C42.5993 38.0414 42.8476 37.3621 43.3443 36.8216C43.841 36.2738 44.4765 35.9998 45.2507 35.9998C46.0469 35.9998 46.6787 36.2592 47.1462 36.7778C47.621 37.2891 47.8584 38.0085 47.8584 38.9362L47.8474 39.0458H43.6292C43.6438 39.5717 43.8191 39.9953 44.1551 40.3167C44.4911 40.6381 44.8928 40.7988 45.3603 40.7988C46.0031 40.7988 46.5071 40.4774 46.8723 39.8346L47.7707 40.2729C47.5297 40.7258 47.1937 41.08 46.7627 41.3357C46.3391 41.5913 45.857 41.7192 45.3165 41.7192ZM43.7059 38.2131H46.7846C46.7554 37.8405 46.602 37.5338 46.3245 37.2927C46.0542 37.0444 45.689 36.9202 45.2288 36.9202C44.849 36.9202 44.5203 37.0371 44.2427 37.2708C43.9725 37.5045 43.7935 37.8186 43.7059 38.2131Z"
      fill="#ABA69C"
    />
    <path
      d="M51.2808 41.5438H50.2728L48.1035 36.1752H49.1991L50.7768 40.3386H50.7988L52.3984 36.1752H53.4721L51.2808 41.5438Z"
      fill="#ABA69C"
    />
    <path
      d="M55.4339 34.3235C55.4339 34.5207 55.3645 34.6887 55.2257 34.8275C55.0869 34.9663 54.9189 35.0357 54.7217 35.0357C54.5245 35.0357 54.3565 34.9663 54.2177 34.8275C54.0789 34.6887 54.0095 34.5207 54.0095 34.3235C54.0095 34.1263 54.0789 33.9583 54.2177 33.8195C54.3565 33.6807 54.5245 33.6113 54.7217 33.6113C54.9189 33.6113 55.0869 33.6807 55.2257 33.8195C55.3645 33.9583 55.4339 34.1263 55.4339 34.3235ZM55.2257 36.1752V41.5438H54.2177V36.1752H55.2257Z"
      fill="#ABA69C"
    />
    <path
      d="M58.8944 41.7192C58.1056 41.7192 57.4555 41.4489 56.9442 40.9084C56.4329 40.3678 56.1772 39.6849 56.1772 38.8595C56.1772 38.0414 56.4256 37.3621 56.9223 36.8216C57.419 36.2738 58.0544 35.9998 58.8287 35.9998C59.6249 35.9998 60.2567 36.2592 60.7242 36.7778C61.199 37.2891 61.4363 38.0085 61.4363 38.9362L61.4254 39.0458H57.2071C57.2217 39.5717 57.397 39.9953 57.733 40.3167C58.069 40.6381 58.4708 40.7988 58.9383 40.7988C59.581 40.7988 60.085 40.4774 60.4503 39.8346L61.3487 40.2729C61.1076 40.7258 60.7716 41.08 60.3407 41.3357C59.917 41.5913 59.435 41.7192 58.8944 41.7192ZM57.2838 38.2131H60.3626C60.3334 37.8405 60.18 37.5338 59.9024 37.2927C59.6322 37.0444 59.267 36.9202 58.8068 36.9202C58.427 36.9202 58.0983 37.0371 57.8207 37.2708C57.5504 37.5045 57.3715 37.8186 57.2838 38.2131Z"
      fill="#ABA69C"
    />
    <path
      d="M69.855 36.1752L68.1238 41.5438H67.0939L65.7572 37.4242L64.4315 41.5438H63.4125L61.6814 36.1752H62.7332L63.9275 40.2291H63.9385L65.2642 36.1752H66.3051L67.6308 40.2291H67.6418L68.8251 36.1752H69.855Z"
      fill="#ABA69C"
    />
    <path
      d="M74.62 40.0538C74.62 40.5212 74.4155 40.9157 74.0065 41.2371C73.5974 41.5585 73.0825 41.7192 72.4616 41.7192C71.9211 41.7192 71.4463 41.5804 71.0373 41.3028C70.6282 41.0179 70.336 40.6454 70.1607 40.1852L71.0592 39.8018C71.1906 40.1232 71.3806 40.3752 71.6289 40.5578C71.8846 40.7331 72.1621 40.8207 72.4616 40.8207C72.783 40.8207 73.0496 40.7513 73.2614 40.6125C73.4806 40.4738 73.5901 40.3094 73.5901 40.1195C73.5901 39.7762 73.3272 39.5242 72.8013 39.3635L71.8809 39.1334C70.8364 38.8705 70.3141 38.3665 70.3141 37.6214C70.3141 37.132 70.5113 36.7412 70.9058 36.4491C71.3075 36.1496 71.8188 35.9998 72.4397 35.9998C72.9145 35.9998 73.3418 36.1131 73.7216 36.3395C74.1087 36.5659 74.379 36.8691 74.5324 37.2489L73.634 37.6214C73.5317 37.395 73.3637 37.2197 73.13 37.0955C72.9035 36.964 72.6479 36.8983 72.363 36.8983C72.1 36.8983 71.8626 36.964 71.6508 37.0955C71.4463 37.227 71.344 37.3877 71.344 37.5776C71.344 37.8844 71.6326 38.1035 72.2096 38.235L73.0204 38.4432C74.0868 38.7061 74.62 39.243 74.62 40.0538Z"
      fill="#ABA69C"
    />
  </SvgIcon>
)

export default GoogleIcon
