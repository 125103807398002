import { useMediaQuery, useTheme } from '@mui/material'
import Carousel, { Alignment } from 'nuka-carousel'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { TypeReviewFields } from 'lib/types/ctf-marketing'
import ReviewCard from 'components/ReviewCard'
import { LeftControl, RightControl } from './CarouselControls'

interface Props {
  activeReviews: TypeReviewFields[]
  activeCategory?: string
}

const ReviewsCarousel: FunctionComponent<Props> = ({
  activeReviews,
  activeCategory,
}) => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const carouselWrapperRef = useRef(null)

  const [slideIndex, setSlideIndex] = useState(0)

  const getSlidesToShow = () => {
    switch (true) {
      case xlUp:
        return 5
      case mdUp:
        return 3
      case smUp:
        return 2
      default:
        return 1
    }
  }

  const slidesToShow = getSlidesToShow()

  const controlIconSize = mdUp ? 'md' : 'sm'

  useEffect(() => {
    const getSlideIndex = () => {
      if (smUp) {
        return activeReviews.length / 2 - 0.5
      }
      return 0
    }

    const index = getSlideIndex()
    activeReviews.length && setSlideIndex(index)
  }, [activeCategory, activeReviews.length, smUp])

  useEffect(() => {
    if (carouselWrapperRef?.current) {
      carouselWrapperRef.current.tabIndex = -1
    }
  }, [])

  return (
    <Carousel
      innerRef={carouselWrapperRef}
      slidesToShow={slidesToShow}
      slideIndex={slideIndex}
      cellAlign={Alignment.Center}
      wrapAround
      frameAriaLabel="Reviews carousel"
      aria-roledescription="carousel"
      renderCenterLeftControls={({ previousSlide }) =>
        activeReviews.length > slidesToShow && (
          <LeftControl onClick={previousSlide} iconSize={controlIconSize} />
        )
      }
      renderCenterRightControls={({ nextSlide }) =>
        activeReviews.length > slidesToShow && (
          <RightControl onClick={nextSlide} iconSize={controlIconSize} />
        )
      }
      renderBottomCenterControls={null}
    >
      {activeReviews.map((activeReview) => (
        <ReviewCard key={activeReview.author} review={activeReview} />
      ))}
    </Carousel>
  )
}

export default ReviewsCarousel
